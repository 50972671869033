/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom-v5-compat';
import { searchResultShape } from '../../shapes';
import Picture from '../Pictures/Picture';
import { convertSpecificationKeyToLabel, url } from '../../utils';
import { trackingShape, withTracking } from '../../context';
import Chips from '../Chips/Chips';
import CustomOverlayTrigger from '../CustomOverlayTrigger';

export const itemIcon = ({ type = '' }) => ({
  Asset: 'icon icon-eh-device',
  Node: 'icon-primary icon-eh-folder',
  Instrumentation: 'icon lcm-iot-icon-instrumentation',
})[type] || 'icon icon-eh-folder';

export function SearchResultItem({
  disabled, item, tracking, onClick, searchOrigin, showSpecifications = true, showAssignedNodes = true, index = 0,
}) {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleOnClick = () => {
    const path = item.type.toLowerCase();
    tracking?.trackEvent('Search-Result-selected', {
      itemType: item.type, index, isDisabled: disabled, searchOrigin,
    });
    navigate(url(`/${path}s/${item.id}`));
  };

  const isSpecificationChipsVisible = showSpecifications && ['Asset', 'Node', 'Instrumentation'].includes(item.type) && !disabled;
  const isAssignedNodesChipsVisible = showAssignedNodes && ['Asset', 'Instrumentation'].includes(item.type) && !disabled;

  const getSpecificationChips = () => {
    const items = Object.keys(item.specifications || {})?.map((specificationKey) => ({
      name: `${convertSpecificationKeyToLabel(specificationKey)}: ${item.specifications[specificationKey].value}`,
      id: `${convertSpecificationKeyToLabel(specificationKey)}: ${item.specifications[specificationKey].value}`,
    }));
    if (items?.length === 0) {
      return [[{
        name: intl.formatMessage({ id: 'search.results.no_specifications' }),
        id: 'specification-chip-undefined',
      }], false];
    }
    return [items, true];
  };

  const getAssignedNodesChips = () => {
    const items = item.nodes ? item.nodes.map((node) => ({
      name: `${node.name}`,
      link: `/nodes/${node.id}`,
      id: `${node.name}-${node.id}`,
    })) : [];
    if (items.length === 0) {
      return [[{
        name: intl.formatMessage({ id: 'search.results.no_assigned_nodes' }),
        id: 'assigned-nodes-chip-undefined',
      }], false];
    }
    return [items, true];
  };

  const specificationChips = isSpecificationChipsVisible ? getSpecificationChips() : [undefined, false];
  const assignedNodesChips = isAssignedNodesChipsVisible ? getAssignedNodesChips() : [undefined, false];

  const blockOnClick = (e) => {
    e.stopPropagation();
  };

  const SearchResultsItem = (
    <li
      id={`search-result-item-${item.id}`}
      className={`list-item${disabled ? ' disabled' : ''} clickable node-item`}
    >
      <div className="list-item-image" role="link" onClick={handleOnClick}>
        <Picture width={50} height={50} src={item.pictureUrl} placeholder={itemIcon(item)} />
      </div>
      <div
        className="list-item-content"
        role="link"
        onClick={() => {
          handleOnClick();
          onClick?.();
        }}
      >
        <div className="list-item-header">{item.title}</div>
        <div className="list-item-details">{item.description}</div>
        {isSpecificationChipsVisible
              && (
              <div className="list-item-chip">
                <i onClick={blockOnClick} data-testid="specification-icon" className="chip-icon icon3-eh-list" />
                <Chips items={specificationChips[0]} withTooltip={specificationChips[1]} />
              </div>
              )}
        {isAssignedNodesChipsVisible
              && (
              <div className="list-item-chip">
                <i onClick={blockOnClick} data-testid="nodes-icon" className="chip-icon icon3-eh-folder" />
                <Chips items={assignedNodesChips[0]} />
              </div>
              )}
      </div>
    </li>
  );

  const displayTooltip = (
    <Tooltip
      id="tooltip"
    >
      {intl.formatMessage({ id: 'subscription.asset_inactive_because_not_assigned_message' })}
    </Tooltip>
  );

  return disabled
    ? (
      <CustomOverlayTrigger placement="bottom" overlay={displayTooltip}>
        <span>
          {SearchResultsItem}
        </span>
      </CustomOverlayTrigger>
    ) : SearchResultsItem;
}

SearchResultItem.propTypes = {
  item: searchResultShape.isRequired,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  tracking: trackingShape,
  onClick: PropTypes.func,
  searchOrigin: PropTypes.string,
  showSpecifications: PropTypes.bool,
  showAssignedNodes: PropTypes.bool,
};

export default withTracking(SearchResultItem);
