import React from 'react';
import PropTypes from 'prop-types';
import Clickable from '../Clickable';
import Icon from '../Icon';
import NamurIcon from '../NamurIcon';
import StatusBadge from '../StatusBadge';

function ListItem({
  actions,
  description,
  disabled,
  icon,
  iconPrimary,
  id,
  image,
  imageUrl,
  namur,
  onClick,
  showDisclosureIndicator,
  status,
  target,
  title,
  value,
}) {
  return (
    <div data-testid={id} id={id} className={`list-item${disabled ? ' disabled' : ''}`}>
      <Clickable target={target} onClick={!disabled ? onClick : null}>
        {imageUrl ? (
          <div className="list-item-image">
            <div className="list-item-image-image" style={{ backgroundImage: `url(${imageUrl})` }} />
            { status ? (<div className="list-item-image-status"><StatusBadge name={status} /></div>) : null}
            { namur ? (<div className="list-item-image-namur"><NamurIcon type={namur} size={15} /></div>) : null}
          </div>
        ) : null}
        { image ? (
          <div className="list-item-image">
            {image}
            { status ? (<div className="list-item-image-status"><StatusBadge name={status} /></div>) : null}
            { namur ? (<div className="list-item-image-namur"><NamurIcon type={namur} size={15} /></div>) : null}
          </div>
        ) : null}
        {icon ? (
          <div className={`list-item-icon${iconPrimary ? ' primary' : ''}`}>
            <Icon name={icon} />
            { status ? (<div className="list-item-icon-status"><StatusBadge name={status} /></div>) : null}
            { namur ? (<div className="list-item-icon-namur"><NamurIcon type={namur} size={15} /></div>) : null}
          </div>
        ) : null}
        <div className="list-item-content">
          <div className="list-item-content-wrapper">
            <div className="list-item-content-title">{title}</div>
            <div className="list-item-content-description">{description}</div>
          </div>
          { value ? (<div className="list-item-content-value">{value}</div>) : null }
        </div>
      </Clickable>
      { actions || showDisclosureIndicator ? (
        <div className="list-item-actions">
          {showDisclosureIndicator ? (<Icon name="icon-eh-arrow-right" />) : null}
          {actions}
        </div>
      ) : null }
    </div>
  );
}

ListItem.propTypes = {
  actions: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.string,
  iconPrimary: PropTypes.bool,
  image: PropTypes.node,
  imageUrl: PropTypes.string,
  namur: PropTypes.string,
  onClick: PropTypes.func,
  showDisclosureIndicator: PropTypes.bool,
  status: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ListItem.defaultProps = {
  actions: undefined,
  description: undefined,
  disabled: false,
  icon: undefined,
  iconPrimary: false,
  image: undefined,
  imageUrl: undefined,
  namur: undefined,
  onClick: undefined,
  showDisclosureIndicator: false,
  status: undefined,
  target: undefined,
  title: undefined,
  value: undefined,
};

export default ListItem;
