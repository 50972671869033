import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownButton, MenuItem,
} from 'react-bootstrap';
import Chip from './Chip';
import { useWindowSize } from '../../context/WindowSizeContext';

export default function Chips({
  items, onRemove, withTooltip = false,
}) {
  const chipsRef = React.useRef(null);
  const [displayedChips, setDisplayedChips] = React.useState();
  const windowSize = useWindowSize();

  React.useEffect(() => {
    /* istanbul ignore next */
    const calculateDisplayedChips = () => {
      if (chipsRef.current) {
        const chipsContainer = chipsRef.current;
        const chipsElements = Array.from(chipsContainer.children);
        // - 50px for the +(number of chips in dropdown) action and for when hidden items are in the thousands
        const availableWidth = Math.floor(chipsContainer.clientWidth) - 50;
        let remainingWidth = availableWidth;
        const visibleChips = [];

        items.forEach((chip) => {
          const chipElement = chipsElements.find((element) => element.textContent === chip.name);
          if (chipElement) {
            // + 4px because gap between the chips
            const chipWidth = Math.floor(chipElement.clientWidth) + 4;
            if (remainingWidth - chipWidth <= 0) return;

            remainingWidth -= chipWidth;
            visibleChips.push(chip);
          }
        });

        setDisplayedChips(visibleChips);
      }
    };

    calculateDisplayedChips();
  }, [items, windowSize.width]);

  const chipsInDropdown = items?.filter((chip) => !displayedChips?.some((displayedChip) => displayedChip.name === chip.name && displayedChip.link === chip.link));
  const dropdown = displayedChips?.length < items.length && (
  <div className="chips-item-actions">
    <div className="list-item-menu">
      <DropdownButton
        onClick={(e) => e.stopPropagation()}
        bsStyle="default"
        title={(<span className="chip more-chips">{`+${items.length - displayedChips.length}`}</span>)}
        noCaret
        id="dropdown-menu"
        pullRight
      >
        {chipsInDropdown.map((chip) => (
          /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
          <li onClick={/* istanbul ignore next */ (e) => e.stopPropagation()} key={`dropchip-${chip.id}`}>
            <Chip id={chip.id} name={chip.name} link={chip.link} onRemove={onRemove} withTooltip={withTooltip} />
          </li>
        ))}
        <MenuItem onClick={/* istanbul ignore next */ (e) => e.stopPropagation()} className="close-item">
          <span data-testid="dropdown-close-button" className="icon icon-eh-show-less" />
        </MenuItem>
      </DropdownButton>
    </div>
  </div>
  );

  return (
    <div className="chips-container">
      <div ref={chipsRef} aria-hidden="true" className="chips">
        {items.map((item) => (
          <Chip id={item.id} key={`chips-container-${item.id}`} name={item.name} link={item.link} onRemove={onRemove} />
        ))}
      </div>
      <div data-testid="chips" className="chips">
        {displayedChips?.map((chip) => (
          <Chip id={chip.id} key={`chips-${chip.id}`} name={chip.name} link={chip.link} onRemove={onRemove} withTooltip={withTooltip} />
        ))}
        {dropdown}
      </div>
    </div>
  );
}

Chips.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ).isRequired,
  onRemove: PropTypes.func,
  withTooltip: PropTypes.bool,
};
