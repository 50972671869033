import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Modal } from 'react-bootstrap';
import {
  Button, ButtonGroup,
} from '../../Form';
import { useApi } from '../../../hooks';
import {
  assetShape, nodeShape, orderShape, documentsShape,
} from '../../../shapes';
import { locale } from '../../../utils/browserUtils';
import { DocumentLanguages } from '../../../constants/DocumentLanguages';
import { getDocumentLanguages } from '../../../utils/documentUtils';
import AllOrMultipleSelect from '../../AllOrMultipleSelect';

function LanguagesSelectionStep({
  exportType, object, handleNext, handleBack, setStepsState, stepsState, includedDocuments,
}) {
  const intl = useIntl();
  const browserLanguageWithoutRegion = locale()?.split('-')[0];
  const api = useApi();
  let allLanguages = DocumentLanguages.map(({ code, translationKey }) => {
    const translation = intl.formatMessage({ id: translationKey });
    return { id: code, code, name: translation };
  }).sort((a, b) => a.name.localeCompare(b.name));

  const moveValue = (sourceArray, targetArray, value) => {
    const index = sourceArray.findIndex((obj) => obj.code === value);
    if (index > -1) {
      targetArray.push(sourceArray.splice(index, 1)[0]);
    }
  };

  let topLanguages = [];
  moveValue(allLanguages, topLanguages, browserLanguageWithoutRegion);
  if (browserLanguageWithoutRegion !== 'en') moveValue(allLanguages, topLanguages, 'en');

  allLanguages = allLanguages.filter((l) => l.code !== 'null');
  const neededLanguagesCodes = getDocumentLanguages(includedDocuments);
  if (neededLanguagesCodes.length > 0) {
    allLanguages = allLanguages.filter((l) => neededLanguagesCodes.includes(l.code));
    topLanguages = topLanguages.filter((l) => neededLanguagesCodes.includes(l.code));
  }

  const { mutate: createExport, isLoading } = api.post.useMutation('/users/current/data_exports');

  const handleConfirmClick = () => {
    const selectedLanguages = stepsState.languagesSelectionStep.selectedItems.map((item) => item.id);
    const languageFilter = selectedLanguages?.length ? { language: selectedLanguages.join(',') } : {};
    const selectedCategories = stepsState.categoriesSelectionStep.selectedItems.map((item) => item.id);
    const categoryFilter = selectedCategories?.length ? { document_category_id: selectedCategories.join(',') } : {};
    const getObject = (type) => {
      if (type.asset) {
        return { asset: { id: object.asset.id } };
      }
      if (type.node) {
        return { node: { id: object.node.id } };
      }
      return { purchase_order: { id: object.order.id } };
    };

    const objectLink = getObject(object);
    createExport({
      type: exportType, ...objectLink, ...languageFilter, ...categoryFilter,
    }, { onSuccess: handleNext });
  };

  const handleSelectionSwitch = (value) => setStepsState({
    ...stepsState,
    languagesSelectionStep: {
      ...stepsState.languagesSelectionStep,
      selectedSwitch: value,
    },
  });

  const handleSpecificSelectionChange = (newSelectedItems) => setStepsState({
    ...stepsState,
    languagesSelectionStep: {
      ...stepsState.languagesSelectionStep,
      selectedItems: newSelectedItems,
    },
  });

  return (
    <>
      <Modal.Body>
        <AllOrMultipleSelect
          title={intl.formatMessage({ id: 'export_files_dialog.file_languages.title' })}
          topItemsLabel={intl.formatMessage({ id: 'export_files_dialog.file_languages.top_items_label' })}
          moreItemsLabel={intl.formatMessage({ id: 'export_files_dialog.file_languages.more_items_label' })}
          itemKey="languages"
          allItems={allLanguages}
          topItems={topLanguages}
          handleSpecificSelectionChange={handleSpecificSelectionChange}
          handleSelectionSwitch={handleSelectionSwitch}
          values={stepsState.languagesSelectionStep}
          includeNoItems={neededLanguagesCodes.length === 0 || neededLanguagesCodes.includes('null')}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            text={intl.formatMessage({ id: 'button.back' })}
            onClick={handleBack}
          />
          <Button
            hero
            text={intl.formatMessage({ id: 'button.export' })}
            fetching={isLoading}
            onClick={handleConfirmClick}
            disabled={stepsState.languagesSelectionStep.selectedItems.length === 0 && stepsState.languagesSelectionStep.selectedSwitch === 'specific_selection'}
          />
        </ButtonGroup>
      </Modal.Footer>
    </>
  );
}

LanguagesSelectionStep.propTypes = {
  stepsState: PropTypes.shape({
    categoriesSelectionStep: PropTypes.shape({
      selectedSwitch: PropTypes.string,
      selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
          ]).isRequired,
          name: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
        }),
      ),
    }),
    languagesSelectionStep: PropTypes.shape({
      selectedSwitch: PropTypes.string,
      selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
          ]).isRequired,
          name: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
        }),
      ),
    }),
  }).isRequired,
  setStepsState: PropTypes.func.isRequired,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  exportType: PropTypes.string.isRequired,
  includedDocuments: documentsShape,
  object: PropTypes.oneOfType([PropTypes.shape({ asset: assetShape }), PropTypes.shape({ node: nodeShape }), PropTypes.shape({ order: orderShape })]),
};

export default LanguagesSelectionStep;
