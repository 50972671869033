import lcmApiClient from './lcmApiClient';
import { extractAsset } from '../extractors/assetsExtractor';
import { extractInstrumentations } from '../extractors/instrumentationsExtractor';

/* istanbul ignore next */
export async function loadAsset(id, parameters) {
  const assetResponse = await lcmApiClient(false).get(`/assets/${id}`, parameters);
  return extractAsset(assetResponse);
}

/* istanbul ignore next */
export async function loadExtendedOrderCodeLookup(asset) {
  let response = null;
  response = (await lcmApiClient()
    .get('/endress/extended_order_code_lookup', { order_code: asset.specifications['eh.pcps.tmp.ordercode'].value })).extended_order_code;
  return response;
}

/* istanbul ignore next */
export async function deleteAsset(id) {
  return lcmApiClient().delete(`/assets/${id}`);
}

/* istanbul ignore next */
export async function unassignNodeFromAsset(assetId, nodeId) {
  return lcmApiClient().delete(`/assets/${assetId}/nodes`, {
    nodes: [{ id: nodeId }],
  });
}

/* istanbul ignore next */
export async function loadAssetInstrumentations(id, parameters) {
  const response = await lcmApiClient().getAll(`/assets/${id}/instrumentations`, parameters);
  return extractInstrumentations(response);
}

/* istanbul ignore next */
export async function assignInstrumentationToAsset(assetId, instrumentationId) {
  return lcmApiClient().post(`/assets/${assetId}/instrumentations`, {
    instrumentations: [{ id: instrumentationId }],
  });
}
