import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useParams } from 'react-router-dom';
import { NotFoundError, ConflictError } from '../../api/errors';
import ConnectedAssetSetupClue from '../Assets/ConnectedAssetSetupClue';
import SubscriptionLimitClue from '../Subscriptions/SubscriptionLimitClue';
import {
  extractNode,
  extractNodes,
  extractInstrumentations,
  extractAssets,
} from '../../extractors';

import {
  accessRightsShape,
  intlShape,
} from '../../shapes';

import Loader from '../Loader';
import Search from '../Search/Search';

import { isSomething, url } from '../../utils';

import AllObjectsHeader from './AllObjectsHeader';
import AllObjectsList from './AllObjectsList';
import AllObjectsNoAssetsFoundClue from './AllObjectsNoAssetsFoundClue';

import { Container, Row, Column } from '../Grid';
import {
  apiShape,
  notifierShape,
  rulesShape,
  subscriptionShape,
  withApi,
  withNotifier,
  withRules,
  withSubscription,
} from '../../context';

import NotFound from '../Errors/NotFound';
import { withAccessRights } from '../../wrappers';
import NestedNodeBreadcrumb from '../Breadcrumb/NestedNodeBreadcrumb';
import { useSearch } from '../../hooks/useSearch';

const initialState = {
  items: [],
  node: undefined,
  nodes: undefined,
  instrumentations: undefined,
  assets: undefined,
  fetching: true,
};

export function AllObjects({
  accessRights,
  additionalCreateMenuItems,
  api,
  intl,
  match,
  notifier,
  rules,
  subscription,
}) {
  const navigate = useNavigate();
  const search = useSearch();

  const [state, setState] = useState(initialState);
  const skipTotalCount = { include_total_count: false };
  const { id: nodeId } = useParams();

  const navigateToFirstResult = search.results?.length === 1 && search.followFirstResult;

  React.useEffect(() => {
    if (navigateToFirstResult) {
      const { href } = search.results[0];
      const parts = href.split(/endress\.com\/v(\d)\/(assets|instrumentations|nodes)\//).slice(-2);
      if (parts.length === 2) {
        navigate(url(`/${parts[0]}/${parts[1]}`), { replace: true });
      }
    }
  }, [navigateToFirstResult]);

  const doHandleLoadMore = async (currentState) => {
    try {
      let nextState = currentState;

      let loadMore = true;
      let updatedItems = nextState.items;

      if (!nextState.nodes || nextState.nodes.nextPageUrl) {
        const includeParam = `${rules.application().get('nodesIncludes')},worst_asset_status`;
        const filter = {
          parent_id: nextState.node ? nextState.node.id : 'null',
          include: includeParam,
          ...skipTotalCount,
          order_by: 'name',
          per_page: 25,
        };

        const nodesResponse = await (nextState.nodes ? api.get(nextState.nodes.nextPageUrl) : api.get('/nodes', filter));
        const nodesResult = {
          nodes: extractNodes(nodesResponse),
          nextPageUrl: nodesResponse.pagination.next,
        };
        loadMore = !nodesResult.nextPageUrl;
        updatedItems = updatedItems.concat(nodesResult.nodes);
        nextState = { ...nextState, nodes: nodesResult };
      }

      if (loadMore && (!nextState.instrumentations || nextState.instrumentations.nextPageUrl)) {
        const filter = {
          include: 'status,type,worst_asset_status',
          ...skipTotalCount,
          system_id: 'null',
          order_by: 'tag',
          node_id: nextState.node ? nextState.node.id : 'null',
          per_page: 25,
        };

        const instrumentationsResponse = await (nextState.instrumentations ? api.get(nextState.instrumentations.nextPageUrl) : api.get('/instrumentations', filter));
        const instrumentationsResult = {
          instrumentations: extractInstrumentations(instrumentationsResponse),
          nextPageUrl: instrumentationsResponse.pagination.next,
        };

        loadMore = !instrumentationsResult.nextPageUrl;
        updatedItems = updatedItems.concat(instrumentationsResult.instrumentations);
        nextState = { ...nextState, instrumentations: instrumentationsResult };
      }

      if (loadMore && (!nextState.assets || nextState.assets.nextPageUrl)) {
        const include = 'product.manufacturer,product.tenant,product.pictures,status';
        const filter = {
          include,
          ...skipTotalCount,
          node_id: nextState.node ? nextState.node.id : 'null',
          order_by: 'serial_number',
          instrumentation_id: 'null',
          per_page: 100,
          parent_id: 'null',
        };

        const assetsResponse = await (nextState.assets ? api.get(nextState.assets.nextPageUrl) : api.get('/assets', filter));
        const assetsResult = {
          assets: extractAssets(assetsResponse),
          nextPageUrl: assetsResponse.pagination.next,
        };

        updatedItems = updatedItems.concat(assetsResult.assets);
        nextState = { ...nextState, assets: assetsResult };
      }

      setState({
        ...nextState, items: updatedItems, fetching: false, initialized: true,
      });
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const handleLoadMore = () => {
    setState({ ...state, fetching: true });
    return doHandleLoadMore({ ...state });
  };

  const handleOnItemRemoved = (item) => {
    const { items } = state;
    setState({ ...state, items: items.filter((i) => i !== item) });
  };

  const handleOnConfirmDelete = async () => {
    const { node } = state;
    try {
      await api.delete(`/nodes/${node.id}`);
      notifier.showSuccess(intl.formatMessage({ id: 'node.actions.delete.notification' }));
      if (node.parent) {
        navigate(url(`/nodes/${node.parent.id}`));
      } else {
        navigate(url('/nodes'));
      }
    } catch (error) {
      if (error instanceof ConflictError) {
        notifier.showError(intl.formatMessage({ id: 'api.error.node.assigned_restriction' }));
      } else {
        notifier.showError(api.translateError(error));
      }
    }
  };

  const loadData = async (nId) => {
    try {
      if (nId) {
        const node = extractNode(await api.get(`/nodes/${nId}`, { include: 'parent,type,type.parent' }));
        await doHandleLoadMore({ ...initialState, node });
      } else {
        await doHandleLoadMore(initialState);
      }
    } catch (error) {
      if (error instanceof NotFoundError) {
        setState({ ...state, showNotFoundError: true });
      } else {
        notifier.showError(api.translateError(error));
      }
    }
  };

  React.useEffect(() => {
    /* istanbul ignore else */
    if (subscription?.subscriptionLoaded) {
      if (!search.searchTerm) {
        setState({ ...initialState, node: state.node });
        loadData(nodeId);
      } else {
        setState({ ...initialState, fetching: false, node: state.node });
      }
    }
  }, [nodeId, subscription, subscription?.id, search.searchTerm]);

  const {
    items,
    node,
    nodes,
    instrumentations,
    assets,
    fetching,
    showNotFoundError,
  } = state;

  const syncNode = nodeId ? node : undefined;

  // if coming from ID the user session does not have the subscriptionAssetQuota, this case is excluded and noAssetFound is shown.
  const showNoAssetsFound = (!fetching && items.length === 0)
      && (!subscription?.asset_quota || subscription?.asset_quota < 0 || (subscription?.usage?.asset_count < subscription?.asset_quota))
      && (!syncNode || accessRights.canUpdate);

  const hideNodes = search.results || search.isSearching;
  const hasMore = !nodes || isSomething(nodes.nextPageUrl)
      || !instrumentations || isSomething(instrumentations.nextPageUrl)
      || !assets || isSomething(assets.nextPageUrl);

  const handleOnNodeSelect = (nextNode) => {
    if (!nextNode) {
      navigate(url('/nodes'));
    } else {
      navigate(url(`/nodes/${nextNode.id}`));
    }
  };

  return showNotFoundError ? (<NotFound />) : (
    <Container>
      <Row>
        <Column>
          <ConnectedAssetSetupClue />
          <SubscriptionLimitClue />
          <NestedNodeBreadcrumb
            id="nodes-header"
            node={syncNode}
            onClick={handleOnNodeSelect}
            firstItemTargetUrl="/nodes"
          />
          <AllObjectsHeader
            accessRights={accessRights}
            additionalCreateMenuItems={additionalCreateMenuItems}
            fetching={fetching}
            node={syncNode}
            match={match}
            onConfirmDelete={handleOnConfirmDelete}
            nodes={nodes}
          />
          <Search scanSearchSource="" />
          {!hideNodes && showNoAssetsFound && <AllObjectsNoAssetsFoundClue nodeId={syncNode?.id} />}
          {!hideNodes && items.length > 0 ? (
            <AllObjectsList
              accessRights={accessRights}
              fetching={fetching}
              hasMore={hasMore}
              items={items}
              onItemRemoved={handleOnItemRemoved}
              loadMore={handleLoadMore}
              node={syncNode}
            />
          ) : null}
          <Loader loading={fetching && !search?.isSearching} />
        </Column>
      </Row>
    </Container>
  );
}

AllObjects.propTypes = {
  accessRights: accessRightsShape,
  additionalCreateMenuItems: PropTypes.node,
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
  notifier: notifierShape.isRequired,
  rules: rulesShape,
  subscription: subscriptionShape,
};

export default injectIntl(withApi(withNotifier(withSubscription(withRules(withAccessRights(AllObjects, 'Node'))))));
