import React from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from 'react-infinite-scroller';
import AssetItem from '../Assets/AssetItem';
import InstrumentationItem from '../Instrumentations/InstrumentationItem';
import NodeItem from '../Nodes/NodeItem';

import List from '../List/List';

import {
  accessRightsShape,
} from '../../shapes';

export function AllObjectsList({
  accessRights,
  fetching,
  hasMore,
  items,
  loadMore,
  node,
  onItemRemoved,
}) {
  return (
    <List id="nodes-list">
      <InfiniteScroll
        initialLoad={false}
        loadMore={loadMore}
        hasMore={!fetching && hasMore}
        threshold={700}
        id="objects"
      >
        {items.map((item) => {
          switch (item.itemType) {
            case 'node':
              return (
                <NodeItem
                  key={`n${item.id}`}
                  node={item}
                  parentNode={node || item}
                  onNodeRemoved={onItemRemoved}
                  showItemMenu
                />
              );
            case 'instrumentation':
              return (
                <InstrumentationItem
                  key={`i${item.id}`}
                  instrumentation={item}
                  parentNode={node}
                  parentAccessRights={accessRights}
                  onInstrumentationRemoved={onItemRemoved}
                  showItemMenu
                />
              );
            case 'asset':
              return (
                <AssetItem
                  key={`a${item.id}`}
                  asset={item}
                  parentNode={node}
                  parentAccessRights={accessRights}
                  onAssetRemoved={onItemRemoved}
                  showItemMenu
                />
              );
            default:
              return null;
          }
        })}
      </InfiniteScroll>
    </List>
  );
}

AllObjectsList.propTypes = {
  accessRights: accessRightsShape,
  fetching: PropTypes.bool,
  hasMore: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  loadMore: PropTypes.func.isRequired,
  node: PropTypes.shape({}),
  onItemRemoved: PropTypes.func,
};

AllObjectsList.defaultProps = {
  accessRights: undefined,
  fetching: false,
  hasMore: false,
  items: [],
  node: undefined,
};

export default AllObjectsList;
